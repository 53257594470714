import FeatureGates from '@atlaskit/feature-gate-js-client';
import type { ApolloError } from 'apollo-client';

import { useIsXflowUser } from '@confluence/onboarding-helpers/entry-points/hooks/useIsXflowUser';

const EXPERIMENT_NAME = 'confluence_surface_import';
const COHORT_KEY = 'cohort';

enum Cohorts {
	ENROLLED = 'enrolled',
	NOT_ENROLLED = 'not-enrolled',
	CONTROL = 'control',
	EXPERIMENT = 'experiment',
}

type useIsEligibleForSurfaceImportType = {
	isEligibleForSurfaceImport: boolean;
	isSurfaceImportExperimentCohort: boolean;
	surfaceImportLoading: boolean;
	sitePersonalizationError: ApolloError | Error | undefined;
};

export const useIsEligibleForSurfaceImport = (): useIsEligibleForSurfaceImportType => {
	// If loading || error, isXflowUser is already false
	const cohort = FeatureGates.getExperimentValue<Cohorts>(
		EXPERIMENT_NAME,
		COHORT_KEY,
		Cohorts.NOT_ENROLLED,
		{
			fireExperimentExposure: false,
		},
	);

	const { isXflowUser, isLoading, error } = useIsXflowUser({
		skip: cohort === Cohorts.NOT_ENROLLED,
	});

	return {
		isEligibleForSurfaceImport: isXflowUser && cohort !== Cohorts.NOT_ENROLLED,
		isSurfaceImportExperimentCohort: isXflowUser && cohort === Cohorts.EXPERIMENT,
		surfaceImportLoading: isLoading,
		sitePersonalizationError: error,
	};
};
